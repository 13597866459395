import React from 'react';
import Layout from '@studionand/depi/src/components/enplus/Layout';
import Editor from '@studionand/depi/src/components/enplus/Editor';
import { LINKS } from '@studionand/depi/src/components/enplus/constants';
import NamedPage from '@studionand/depi/src/components/NamedPage';
import { transformEnplusLink } from '@studionand/depi/src/namedRoutes';

const config = transformEnplusLink(LINKS.pellets);

const EnplusNamedPage = props => {
  return (
    <NamedPage
      {...props}
      config={config}
      layoutComponent={Layout}
      editorComponent={Editor}
    />
  );
};

EnplusNamedPage.getInitialProps = NamedPage.getInitialProps(config);

export default EnplusNamedPage;
